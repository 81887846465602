import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import FaqItem from './components/FaqItem';

type Props = {
  faqTitle: StringField;
  faqItems?: Array<{
    name: StringField;
    text: StringField;
  }>;
};

function Faq({ faqTitle, faqItems }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title dangerouslySetInnerHTML={{ __html: faqTitle ?? '' }} />
        </Top>
        <Content>
          {faqItems?.map((faqItem, index) => {
            return <FaqItem key={index} faqItem={faqItem} index={index} />;
          })}
        </Content>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.white};
`;

const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;
const Top = styled.div``;

const Content = styled.div`
  margin-top: 36px;

  ${media.laptop(css`
    margin-top: 16px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
  `)}
  
  ${media.mobile(css`
    margin-top: 11px;
  `)}
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export default Faq;
