import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { NewsListItem } from '@/typings/model';
import Link from '@/components/Link';

import NewsCard from './components/NewsCard';

interface Props {
  newsList: Array<NewsListItem>;
}

function News({ newsList }: Props) {
  return (
    <Component>
      <Top>
        <Left>
          <Title>Последние новости</Title>
        </Left>
        <Right>
          <MoreBtn to={'/news'}>
            Все новости
            <ArrowRight />
          </MoreBtn>
        </Right>
      </Top>
      <Content>
        <List>
          {newsList.slice(0, 3).map((newsItem) => {
            return (
              <Cell key={newsItem.title}>
                <NewsCard newsItem={newsItem} />
              </Cell>
            );
          })}
        </List>
      </Content>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  ${media.mobile(css`
    position: relative;
    padding-bottom: 56px;
  `)}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Content = styled.div`
  margin-top: 78px;

  ${media.laptop(css`
    margin-top: 47px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

const Left = styled.div`
  max-width: 80%;

  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  display: flex;
  align-items: flex-end;

  padding: 0 5px 2px 0;

  ${media.laptop(css`
    padding: 0 2px 4px 0;
  `)}

  ${media.tabletSmall(css`
    padding: 0 2px 0 0;
  `)}

  ${media.mobile(css`
    position: absolute;
    bottom: 0;
    right: 22px;
    padding: 0;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const MoreBtn = styled(Link)`
  display: flex;
  align-items: center;
  width: auto;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  padding: 0;
  color: ${colors.black};
  transition: all 0.3s linear;

  &:hover {
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}
  ${media.tabletSmall(css`
    font-size: 14px;
    line-height: 26px;
  `)}
  ${media.mobile(css`
    font-size: 14px;
    line-height: 26px;
  `)}
  svg {
    margin-left: 36px;
    width: 24px;
    height: 40px;

    ${media.laptop(css`
      margin-left: 8px;
      width: 18px;
      height: 15px;
    `)}

    ${media.tabletSmall(css`
      margin-left: 10px;
      height: 14px;
    `)}

    ${media.mobile(css`
      margin-left: 8px;
    `)}
  }
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  ${media.mobile(css`
    flex-wrap: wrap;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 33.33%;
  max-width: 33.33%;
  border-right: 1.5px solid ${colors.gray60};
  border-top: 1.5px solid ${colors.gray60};
  border-bottom: 1.5px solid ${colors.gray60};

  ${media.tablet(css`
    border-right: 1px solid ${colors.gray60};
    border-top: 1px solid ${colors.gray60};
    border-bottom: 1px solid ${colors.gray60};
  `)}
  ${media.mobile(css`
    flex: 1 1 100%;
    max-width: 100%;
    border: 1px solid ${colors.gray60};
  `)}
  &:last-child {
    border-right: none;

    ${media.mobile(css`
      border-right: 1px solid ${colors.gray60};
    `)}
  }
`;

export default News;
