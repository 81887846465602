import React from 'react';

import { Page } from '@tager/web-components';

import Layout from '@/components/Layout';
import {
  Container,
  Hero,
  HeroInner,
  Inner,
  Static,
  Title,
} from '@/modules/DefaultTemplate';
import useSettingItem from '@/hooks/useSettingItem';
import Breadcrumbs from '@/components/Breadcrumbs';
import { BreadcrumbsItemType } from '@/typings/model';
import { getBreadcrumbsList } from '@/utils/common';

function NotFoundPage() {
  const title = useSettingItem('NOT_FOUND_PAGE_TITLE');
  const text = useSettingItem('NOT_FOUND_PAGE_TEXT');

  const breadcrumbList: Array<BreadcrumbsItemType> = getBreadcrumbsList(
    title ?? '',
    undefined
  );

  return (
    <Page title={title}>
      <Layout>
        <Container>
          <Breadcrumbs breadcrumbs={breadcrumbList} />
          <Hero>
            <HeroInner>
              <Title>{title}</Title>
            </HeroInner>
          </Hero>
          <Static>
            <Inner
              dangerouslySetInnerHTML={{
                __html: text ?? '',
              }}
            />
          </Static>
        </Container>
      </Layout>
    </Page>
  );
}

export default NotFoundPage;
