import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { NewsListItem } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

interface Props {
  newsItem: NewsListItem;
}

function NewsCard({ newsItem }: Props) {
  const { title, excerpt, coverImage, urlAlias } = newsItem;
  return (
    <Component>
      {urlAlias && <ComponentLink to={`/news/${urlAlias}`} />}
      <Inner>
        <Top>
          <Image>
            {coverImage ? (
              <Picture
                mobileSmall={convertThumbnailToPictureImage(coverImage)}
              />
            ) : null}
          </Image>
        </Top>
        <Description>
          <Title>{title}</Title>
          <Text>{excerpt}</Text>
        </Description>
      </Inner>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;
  padding: 60px 60px 52px;
  display: flex;
  flex-direction: column;

  ${media.laptop(css`
    padding: 40px 40px 38px;
  `)}

  ${media.tablet(css`
    padding: 20px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 20px 50px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 46px;

  ${media.laptop(css`
    margin-bottom: 32px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const Description = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Image = styled.div`
  width: 100%;
  border-radius: 36px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s linear;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  ${media.laptop(css`
    border-radius: 24px;
  `)}

  ${media.tabletOnly(css`
    border-radius: 14px;
  `)}

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Title = styled.h4`
  margin: 0 0 37px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black400};
  transition: all 0.3s linear;

  ${media.laptop(css`
    margin-bottom: 17px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Text = styled.p`
  flex: 0 0 78px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black600};

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s linear;

  ${media.laptop(css`
    flex: 0 0 72px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    -webkit-line-clamp: 5;
    flex: 0 0 110px;
    line-height: 22px;
    color: ${colors.grayLight};
  `)}
  
  ${media.mobile(css`
    -webkit-line-clamp: 3;
    flex: 0 0 60px;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.black600};
  `)}
`;

const Component = styled.div`
  position: relative;
  transition: all 0.3s linear;
  background: ${colors.white};
  height: 100%;
  width: 100%;

  ${media.laptopUp(css`
    &:hover {
      background: ${colors.black};

      ${Image} {
        border-radius: 0;
      }
      ${Title} {
        color: ${colors.white};
      }
      ${Text} {
        color: ${colors.white};
      }
    }
  `)}
`;

export default NewsCard;
