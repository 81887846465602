import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

interface Props {
  label: StringField;
  isWhite?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  isHover?: boolean;
  isActive?: boolean;
}

function InformationLabel({
  label,
  isWhite,
  isSmall,
  isMedium,
  isHover,
  isActive,
}: Props) {
  return (
    <Component isWhite={isWhite} isHover={isHover} isActive={isActive}>
      <Inner isSmall={isSmall} isMedium={isMedium}>
        <Label>{label ?? ''}</Label>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{
  isWhite?: boolean;
  isHover?: boolean;
  isActive?: boolean;
}>`
  transition: all 0.3s linear;
  background: ${colors.black};
  color: ${colors.white};
  border-radius: 50px;

  ${media.laptop(css`
    border-radius: 35px;
  `)}

  ${(props) =>
    props.isWhite
      ? css`
          background: ${colors.white};
          color: ${colors.black};
        `
      : ''}
  
  ${(props) =>
    props.isHover
      ? css`
          &:hover {
            background: ${colors.red200};
            cursor: pointer;
          }
        `
      : ''}
  
    ${(props) =>
    props.isActive
      ? css`
          background: ${colors.red200};
        `
      : ''}
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    line-height: 20px;
  `)}
`;

const Inner = styled.div<{ isSmall?: boolean; isMedium?: boolean }>`
  padding: 32px 38px;

  ${media.laptop(css`
    padding: 24px 32px;
  `)};

  ${media.tablet(css`
    padding: 14px 22px;
  `)};

  ${media.mobile(css`
    padding: 12px 20px;
  `)};

  ${(props) =>
    props.isSmall
      ? css`
          padding: 22px 32px;

          ${Label} {
            font-size: 20px;
            line-height: 26px;
          }

          ${media.laptop(css`
            padding: 16px 24px;

            ${Label} {
              font-size: 16px;
              line-height: 24px;
            }
          `)}

          ${media.tablet(css`
            padding: 14px 22px;

            ${Label} {
              line-height: 22px;
            }
          `)}
          
          ${media.mobile(css`
            padding: 12px 20px;

            ${Label} {
              line-height: 20px;
            }
          `)}
        `
      : ''}

  ${(props) =>
    props.isMedium
      ? css`
          padding: 30px 42px;

          ${media.laptop(css`
            padding: 24px 32px;

            ${Label} {
              font-size: 24px;
              line-height: 32px;
            }
          `)}

          ${media.tablet(css`
            padding: 16px 24px;

            ${Label} {
              font-size: 20px;
              line-height: 26px;
            }
          `)}
          
          ${media.mobile(css`
            padding: 12px 20px;

            ${Label} {
              font-size: 16px;
              line-height: 20px;
            }
          `)}
        `
      : ''}
`;

export default InformationLabel;
